/* eslint-disable sort-keys */
import { apiFetch } from '@api/base';
import { notify } from '@lib/notify';
import type { TAction, TDispatch } from '@models/types';

import { PerhitunganPelunasanActionType } from './types';

import type { PelunasanDataModel, PerhitunganDataModel, PerhitunganPelunasanAction, PerhitunganPelunasanModel, SummaryTableDataModel, TablePenumpangDataModel } from './types';

const DUMMY_PELUNASAN: PelunasanDataModel = {
  bookingFeeTotal: 0,
  downPaymentTotal: 0,
  pax: 0,
  totalSettlement: 0
};

const DUMMY_TABLE_PESANAN: PerhitunganDataModel[] = [
  {
    departure: 'string',
    orderNumber: 'string',
    orderStatus: 'string',
    passenger: 'string',
    pax: 0,
    pnr: 'string',
    pricePerPax: 0
  }

];

const DUMMY_TABLE_PENUMPANG: TablePenumpangDataModel[] = [
  {
    id: 1,
    number: 1,
    name: 'Ahmad  / MR',
    jenis_kelamin: 'Laki-laki',
    tanggal_lahir: '31/12/1989',
    passpor: 'A8420383',
    tanggal_habis_berlaku: '31/12/1989'
  },
  {
    id: 2,
    number: 2,
    name: ' Syarifudin / MR',
    jenis_kelamin: 'Laki-laki',
    tanggal_lahir: '31/12/1989',
    passpor: 'A8420383',
    tanggal_habis_berlaku: '31/12/1989'
  },
  {
    id: 3,
    number: 3,
    name: 'Ahmad Syarifudin / MR',
    jenis_kelamin: 'Laki-laki',
    tanggal_lahir: '31/12/1989',
    passpor: 'A8420383',
    tanggal_habis_berlaku: '31/12/1989'
  }

];

const DUMMY_DENDA: SummaryTableDataModel[] = [
  {
    description: 'CGK - JED - CGK',
    quantity: 36,
    remark: 'Tiket SV121, SV122 22 Agustus 2022',
    sub_total: 5400000,
    type: '',
    unit_price: 5400000
  }
];

const DUMMY_SUMMARY_TABLE_2: SummaryTableDataModel[] = [
  {
    description: 'CGK - JED - CGK',
    quantity: 36,
    remark: 'Tiket SV121, SV122 22 Agustus 2022',
    sub_total: 5400000,
    type: '',
    unit_price: 5400000
  }
];

const DUMMY_PERHITUNGAN_PELUNASAN_2: PerhitunganDataModel[] = [
  {
    departure: 'string',
    orderNumber: 'string',
    orderStatus: 'string',
    passenger: 'string',
    pax: 0,
    pnr: 'string',
    pricePerPax: 0
  }
];

const PerhitunganPelunasanDefault: PerhitunganPelunasanModel = {
  perhitungan: [],
  jumlahPax: {
    materialization: '',
    minimumPax: ''
  },
  summaryTable: {
    data: [],
    error: false,
    message: '',
    reducePassengers: 0
  },
  pelunasan: {
    bookingFeeTotal: 0,
    downPaymentTotal: 0,
    pax: 0,
    totalSettlement: 0
  },
  tablePesanan: [],
  tablePenumpang: [],
  perhitungan_2: [],
  summaryTable_2: [],
  pelunasan_2: {
    bookingFeeTotal: 0,
    downPaymentTotal: 0,
    pax: 0,
    totalSettlement: 0
  },
  denda: []
};

const PerhitunganPelunasanReducer = (state: PerhitunganPelunasanModel = PerhitunganPelunasanDefault, action: PerhitunganPelunasanAction): PerhitunganPelunasanModel => {
  switch (action.type) {
    case PerhitunganPelunasanActionType.PostPerhitunganList:
      return { ...state, perhitungan: action.data };
    case PerhitunganPelunasanActionType.GetJumlahPax:
      return { ...state, jumlahPax: action.data };
    case PerhitunganPelunasanActionType.PostSummaryTable:
      return { ...state, summaryTable: action.data };
    case PerhitunganPelunasanActionType.GetPelunasan:
      return { ...state, pelunasan: action.data };
    case PerhitunganPelunasanActionType.PostTablePesanan:
      return { ...state, tablePesanan: action.data };
    case PerhitunganPelunasanActionType.PostTablePenumpang:
      return { ...state, tablePenumpang: action.data };
    case PerhitunganPelunasanActionType.PostPerhitunga2List:
      return { ...state, perhitungan_2: action.data };
    case PerhitunganPelunasanActionType.PostSummaryTable2:
      return { ...state, summaryTable_2: action.data };
    case PerhitunganPelunasanActionType.GetPelunasan2:
      return { ...state, pelunasan_2: action.data };
    case PerhitunganPelunasanActionType.PostDendaTable:
      return { ...state, denda: action.data };

    default:
      return { ...state };
  }
};

const PerhitunganPelunasanCommand = {
  getJumlahPax: (params: string, token: string): TAction<PerhitunganPelunasanAction, void> => {
    return (dispatch: TDispatch<PerhitunganPelunasanAction>) => {
      return apiFetch(token).get(`${window.NX?.env?.BaseUrl}/order/1.0/agent/get-materialization-pax?number=${params}`)
        .then((response) => {
          dispatch({
            data: response.data.data,
            type: PerhitunganPelunasanActionType.GetJumlahPax
          });
        });
    };
  },
  PostSummaryTableList: (payload: unknown, token: string): TAction<PerhitunganPelunasanAction, void> => {
    return async (dispatch: TDispatch<PerhitunganPelunasanAction>) => {
      const res = await apiFetch(token).post(`${window.NX?.env?.BaseUrl}/order/1.0/agent/calculate-materialization`, payload);
      if (res.status === 200) {
        dispatch({
          data: res.data,
          type: PerhitunganPelunasanActionType.PostSummaryTable
        });

        if (res.data.data.length !== 0) {
          return res.data;
        }
      } else {
        notify.error('Terjadi kesalahan! Silahkan coba beberapa saat lagi');
      }
    };
  },
  getPelunasan: (payload: unknown, token: string): TAction<PerhitunganPelunasanAction, void> => {
    return (dispatch: TDispatch<PerhitunganPelunasanAction>) => {
      return apiFetch(token).post(`${window.NX?.env?.BaseUrl}/order/1.0/agent/calculate-payment`, payload)
        .then((response) => {
          dispatch({
            data: response.data.data,
            type: PerhitunganPelunasanActionType.GetPelunasan
          });
        });
    };
  },
  PostTablePesananList: (): TAction<PerhitunganPelunasanAction, void> => {
    return (dispatch: TDispatch<PerhitunganPelunasanAction>) => {
      return Promise.resolve().then(() => {
        dispatch({
          data: DUMMY_TABLE_PESANAN,
          type: PerhitunganPelunasanActionType.PostTablePesanan
        });
      });
    };
  },
  PostTablePenumpangList: (): TAction<PerhitunganPelunasanAction, void> => {
    return (dispatch: TDispatch<PerhitunganPelunasanAction>) => {
      return Promise.resolve().then(() => {
        dispatch({
          data: DUMMY_TABLE_PENUMPANG,
          type: PerhitunganPelunasanActionType.PostTablePenumpang
        });
      });
    };
  },
  PostPerhitungan2List: (): TAction<PerhitunganPelunasanAction, void> => {
    return (dispatch: TDispatch<PerhitunganPelunasanAction>) => {
      /*
       * Return apiFetch(token).get(`/specsearch/1.0/agent/search/order-summary?orderId=${params}`)
       *   .then((response) => {
       *     dispatch({
       *       data: response.data.data,
       *       type: PerhitunganPelunasanActionType.GetOrderSummaryList
       *     });
       *   });
       */
      return Promise.resolve().then(() => {
        dispatch({
          data: DUMMY_PERHITUNGAN_PELUNASAN_2,
          type: PerhitunganPelunasanActionType.PostPerhitunga2List
        });
      });
    };
  },
  PostSummaryTable2List: (): TAction<PerhitunganPelunasanAction, void> => {
    return (dispatch: TDispatch<PerhitunganPelunasanAction>) => {
      /*
       * Return apiFetch(token).get(`/specsearch/1.0/agent/search/order-summary?orderId=${params}`)
       *   .then((response) => {
       *     dispatch({
       *       data: response.data.data,
       *       type: PerhitunganPelunasanActionType.PostSummaryTable2
       *     });
       *   });
       */
      return Promise.resolve().then(() => {
        dispatch({
          data: DUMMY_SUMMARY_TABLE_2,
          type: PerhitunganPelunasanActionType.PostSummaryTable2
        });
      });
    };
  },
  getPelunasan2: (): TAction<PerhitunganPelunasanAction, void> => {
    return (dispatch: TDispatch<PerhitunganPelunasanAction>) => {
      /*
       * Return apiFetch(token).get(`/specsearch/1.0/agent/search/order-summary?orderId=${params}`)
       *   .then((response) => {
       *     dispatch({
       *       data: response.data.data,
       *       type: PerhitunganPelunasanActionType.GetOrderSummaryList
       *     });
       *   });
       */
      return Promise.resolve().then(() => {
        dispatch({
          data: DUMMY_PELUNASAN,
          type: PerhitunganPelunasanActionType.GetPelunasan2
        });
      });
    };
  },
  PostDendaList: (): TAction<PerhitunganPelunasanAction, void> => {
    return (dispatch: TDispatch<PerhitunganPelunasanAction>) => {
      /*
       *     Return apiFetch(token).get(`/specsearch/1.0/agent/search/order-summary?orderId=${params}`)
       *       .then((response) => {
       *         dispatch({
       *           data: response.data.data,
       *           type: PerhitunganPelunasanActionType.PostDendaTable
       *         });
       *       });
       */
      return Promise.resolve().then(() => {
        dispatch({
          data: DUMMY_DENDA,
          type: PerhitunganPelunasanActionType.PostDendaTable
        });
      });
    };
  },
  PostConfirmFullPayment: (payload: unknown, token: string): Promise<string> => {
    return apiFetch(token).post('/order/1.0/agent/confirm-full-payment', payload)
      .then((response) => {
        if (response.status === 200) {
          notify.success('Persetujuan pelunasan berhasil');

          return 'ok';
        }

        return 'err';
      }).catch(() => {
        return 'err';
      });
  }
};

export { PerhitunganPelunasanCommand, PerhitunganPelunasanReducer, PerhitunganPelunasanDefault };
